import { graphql } from 'gatsby';
import React from 'react';

import { useBlogCategory } from '../GraphQl/useBlogCategory';
import { BlogPager } from '../components/blog/pagination';
import Layout from '../components/layout';
import { PageMeta } from '../components/pageMeta';
import { BlogTab } from './blogTab';

const BlogCategory = ({ data: { page, posts }, pageContext }) => {
  const categories = useBlogCategory();
  const { meta, slug } = page;

  return (
    <Layout>
      <PageMeta {...meta} />
      <BlogTab
        categories={categories}
        posts={posts}
        pageContext={pageContext}
      />
      <BlogPager
        pageContext={pageContext}
        pathPrefix={`news/${slug && slug.current}`}
      />
    </Layout>
  );
};

export default BlogCategory;

export const pageQuery = graphql`
  query blogCategoryQuery(
    $skip: Int!
    $limit: Int!
    $slug: String!
    $id: String!
  ) {
    page: sanityBlogCategory(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
    }
    posts: allSanityNewsArticle(
      skip: $skip
      limit: $limit
      filter: { category: { _id: { eq: $id } } }
    ) {
      nodes {
        id
        heading
        author
        date
        content: _rawContent(resolveReferences: { maxDepth: 100 })
        slug {
          current
        }
        _rawImage
        category {
          slug {
            current
          }
        }
      }
    }
  }
`;
