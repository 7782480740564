import cx from "classnames";
import { useEmblaCarousel } from "embla-carousel/react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

import { NewsCard } from "../components/blog/newsCard";
import { BlogPager } from "../components/blog/pagination";
import { PageMeta } from "../components/pageMeta";

import CarrotDown from "../images/carrotdownteal.svg";

const Chevron = ({ isOpen }) => {
  const variant = isOpen ? "opened" : "closed";

  const chevVars = {
    closed: {
      rotate: 0,
      transition: {
        duration: 0.3,
      },
    },
    opened: {
      rotate: 180,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div variants={chevVars} initial="closed" animate={variant}>
      <CarrotDown />
    </motion.div>
  );
};

const Accordion = ({ i, expanded, setExpanded, content, title }) => {
  const isOpen = i === expanded;

  return (
    <>
      <motion.div
        className="pt-12 pb-2 border-b border-dark-navy flex flex-row justify-between items-center text-white px-4"
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        <p className="text-base md:text-xl text-dark-navy font-bold capitalize">
          {title.toLowerCase() === "insights" ? "All" : title}
        </p>
        <Chevron isOpen={isOpen} />
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              variants={{
                collapsed: { scale: 1, opacity: 0 },
                open: { scale: 1, opacity: 1 },
              }}
              transition={{ duration: 0.5 }}
              className="text-left pt-4"
            >
              {title.toLowerCase() !== "insights" && (
                <Link className="pb-2 px-4 flex" to="/insights">
                  All
                </Link>
              )}
              {content.map(
                (c, i) =>
                  c.title.toLowerCase() !== title.toLowerCase() && (
                    <Link
                      className="px-4 pb-2 flex flex-col"
                      to={`/insights/${c.slug.current}`}
                    >
                      {c.title}
                    </Link>
                  )
              )}
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export const BlogTab = ({ categories, posts, pageContext }) => {
  const [expanded, setExpanded] = useState(false);

  // Embla Setup
  const [emblaRef] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    containScroll: true,
    startIndex: 0,
  });

  const currentPage = pageContext.slug;
  const title = currentPage?.replace("-", " ") ?? "Insights";

  const [currentMeta, setCurrentMeta] = useState({
    metaDescription: "Insights",
    metaTitle: "Insights",
    _key: null,
    _rawMetaImage: null,
  });

  useEffect(() => {
    categories.forEach((category) => {
      if (category.slug.current === currentPage) {
        setCurrentMeta(category.meta);
      }
    });
  }, []);

  return (
    <>
      <PageMeta {...currentMeta} />
      <div className="mt-16 lg:mt-60 grid grid-cols-14 text-dark-navy overflow-hidden">
        <h1 className="col-start-2 col-end-14 text-4xl lg:text-6xl text-center text-dark-navy capitalize">
          {title}
        </h1>
        <div
          ref={emblaRef}
          className="hidden lg:block cursor-none col-start-1 col-end-15 lg:col-start-2 lg:col-end-14"
        >
          <AnimateSharedLayout>
            <div className="mx-8 flex flex-row justify-between items-center lg:justify-center lg:mx-auto lg:gap-8 mt-16 lg:mt-32 no-transform text-xs lg:text-lg uppercase">
              <Link
                className="pb-2 px-3 mx-2 categories"
                to="/insights"
                activeStyle={{
                  fontWeight: 900,
                  borderBottom: "3px solid",
                }}
              >
                All
              </Link>
              {categories.map((c, i) => {
                return (
                  <Link
                    className=" px-3 mx-2 pb-2 categories whitespace-nowrap"
                    to={`/insights/${c.slug.current}`}
                    activeStyle={{
                      fontWeight: 900,
                      borderBottom: "3px solid",
                    }}
                  >
                    {c.title}
                  </Link>
                );
              })}
            </div>
          </AnimateSharedLayout>
        </div>

        {/* MOBILE DROPDOWN */}
        <div className="lg:hidden col-start-3 col-end-13">
          <Accordion
            i={0}
            expanded={expanded}
            setExpanded={setExpanded}
            content={categories}
            title={title}
          />
        </div>

        <div className="hidden lg:block w-full border-dark-navy border-t col-start-1 col-end-15 lg:col-start-2 lg:col-end-14" />

        <div className="col-start-2 col-end-14 flex flex-row gap-6 lg:gap-14 grid grid-cols-4 pt-12 lg:pt-24">
          {posts.nodes.map((post, i) => (
            <div
              className={cx({
                "col-start-1 col-end-5 lg:col-end-3": i === 0,
                "col-start-1 lg:col-start-3 col-end-5": i === 1,
                "w-full col-span-2 lg:col-span-1": i !== 0 && i !== 1,
              })}
            >
              <NewsCard key={i} post={post} i={i} />
            </div>
          ))}
        </div>
      </div>

      <div className="mt-16 mb-40">
        <BlogPager pageContext={pageContext} />
      </div>
    </>
  );
};
