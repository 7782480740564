import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import cx from "classnames";

export const NewsCard = ({ post, i }) => {
  return (
    <div
      className={cx("mb-8", {
        "hidden lg:block": i === 6 || i === 7 || i === 8 || i === 9,
      })}
    >
      <Link to={`/insights/${post.category.slug.current}/${post.slug.current}`}>
        {post._rawImage && (
          <div
            className={cx("relative overflow-hidden", {
              "aspect-w-6 aspect-h-4": i === 0 || i === 1,
            })}
          >
            <Image
              className={cx(
                "w-full transform hover:scale-105 ease-in-out duration-300",
                { "absolute top-0 left-0 object-cover": i === 0 || i === 1 }
              )}
              {...post._rawImage}
            />
          </div>
        )}
        <h3
          className={cx("text-dark-navy my-3 lg:my-5", {
            "text-lg lg:text-4xl leading-6 font-bold lg:font-normal lg:leading-12":
              i === 0 || i === 1,
            "text-base lg:text-xl leading-6 font-bold lg:font-normal lg:leading-8":
              i !== 0 || i !== 1,
          })}
        >
          {post.heading}
        </h3>
        <p className="font-bold text-teal text-base lg:text-xl">
          Read more &gt;
        </p>
      </Link>
    </div>
  );
};
