import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

import ChevronL from "../../svg/left.svg";
import ChevronR from "../../svg/right.svg";

export const BlogPager = ({ pageContext, pathPrefix = "insights" }) => {
  const { numberOfPages, pageNumber, previousPagePath, nextPagePath } =
    pageContext;
  const pageLinks = [];
  for (let i = 0; i < numberOfPages; i++) {
    pageLinks.push({
      label: i + 1,
    });
  }
  return (
    numberOfPages > 1 && (
      <div className="flex gap-x-3 justify-center py-10">
        <Link
          className="w-10 h-10 flex justify-center items-center border border-teal rounded-full"
          to={previousPagePath !== "" && previousPagePath}
        >
          <ChevronL className="w-2 text-peach-100 fill-current" />
        </Link>
        {pageLinks.map(({ label }, index) => (
          <Link
            key={index}
            to={`/${pathPrefix}${index > 0 ? `/${index + 1}` : ""}`}
            className={cx(
              "w-10 h-10 border border-teal flex justify-center items-center rounded-full",
              {
                "text-dark-navy": index !== pageNumber,
                "bg-teal border-teal text-white": index === pageNumber,
              }
            )}
          >
            {label}
          </Link>
        ))}
        <Link
          className="w-10 h-10 flex justify-center items-center border border-teal rounded-full"
          to={nextPagePath !== "" && nextPagePath}
        >
          <ChevronR className="w-2 text-peach-100 fill-current" />
        </Link>
      </div>
    )
  );
};
